import { computed, toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';

import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';

export default class CasinoLoyaltyProgramPagePrefetch extends AbstractPrefetch {
  prefetch(): AbstractPrefetchResult {
    const onboarding = useOnboarding();
    const casinoLoyaltyStore = useCasinoLoyaltyStore();

    const hasOnboarding = computed(() => !!onboarding.getOnboardingById(OnboardingIdsEnum.EGS_LOYALTY_PROGRAM));
    const currentLevelId = toRef(() => casinoLoyaltyStore.currentLevelId);
    const hideCasinoLoyaltyOnboardingPage = toRef(() => casinoLoyaltyStore.hideCasinoLoyaltyOnboardingPage);

    if (
      currentLevelId.value > 0
      && !hideCasinoLoyaltyOnboardingPage.value
      && hasOnboarding.value
    ) {
      casinoLoyaltyStore.setHideCasinoLoyaltyOnBoardingPageState(true);

      return ({
        name: RouteName.ONBOARDING,
        params: { id: OnboardingIdsEnum.EGS_LOYALTY_PROGRAM },
      });
    }
  }
}
