<script setup lang="ts">
import useCasinoLoyaltyProgramLevelDoneModal

  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramLevelDoneModal/composables/useCasinoLoyaltyProgramLevelDoneModal';
import CasinoLoyaltyProgramProgress
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramProgress/CasinoLoyaltyProgramProgress.vue';
import { ModalSelector } from 'web/src/modules/core/enums';

const {
  title,
  counterLevels,
  progressProperties,
  isShowLevelDoneModal,
  close,
} = useCasinoLoyaltyProgramLevelDoneModal();
</script>

п
<template>
  <Teleport v-auto-id="'CasinoLoyaltyProgramLevelDoneModal'"
    v-if="isShowLevelDoneModal"
    :to="ModalSelector.BODY"
  >
    <div
      :class="$style['casino-loyalty-program-level-done-modal']"
      @click="close"
    >
      <div :class="$style['casino-loyalty-program-level-done-modal__icon']">
        <CasinoLoyaltyProgramProgress
          v-bind="progressProperties"
        />
      </div>
      <div :class="$style['casino-loyalty-program-level-done-modal__content']">
        <div :class="$style['casino-loyalty-program-level-done-modal__title']">
          {{ title }}
        </div>
        <div :class="$style['casino-loyalty-program-level-done-modal__descr']">
          {{ counterLevels }} {{ $t('WEB2_LOYALTY_PROGRAM_LEVELS_UNLOCKED') }}
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" module>
.casino-loyalty-program-level-done-modal {
  @include regular\14\16\025;

  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 280px;
  height: 62px;
  padding: 6px;
  color: var(--DButton);
  background: rgba(255, 255, 255, 0.95);
  border-radius: 100px 12px 12px 100px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }

  &__content {
    padding-left: 10px;
  }

  &__title {
    margin-bottom: 4px;
    font-weight: 500;
  }

  @include is-app-layout-desktop {
    top: 80px;
  }
}
</style>
