<script lang="ts" setup>
import DefaultModal from 'web/src/components/Modal/DefaultModal/DefaultModal.vue';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import BonusLoyaltyProgramBalance
  from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';
import CasinoLoyaltyLobby from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyLobby/CasinoLoyaltyLobby.vue';
import CasinoLoyaltyProgramCurrentLevelCard

  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCurrentLevelCard/CasinoLoyaltyProgramCurrentLevelCard.vue';
import CasinoLoyaltyProgramIcon
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import CasinoLoyaltyProgramList
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramList/CasinoLoyaltyProgramList.vue';
import CasinoLoyaltyProgramNextLevelInfo

  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramNextLevelInfo/CasinoLoyaltyProgramNextLevelInfo.vue';
import CasinoLoyaltyProgramSwiper
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramSwiper/CasinoLoyaltyProgramSwiper.vue';
import CasinoLoyaltyProgramTitle
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramTitle/CasinoLoyaltyProgramTitle.vue';
import { CasinoLoyaltyProgramCardSizes } from 'web/src/modules/casino-loyalty-program/store/types';

import type { CasinoLoyaltyProgramRoutePageProps } from './types';
import { useCasinoLoyaltyProgramRoutePage } from './composables';

const props = withDefaults(defineProps<CasinoLoyaltyProgramRoutePageProps>(), {
  id: '0',
});

const {
  selectedId,
  showLevelDescription,
  currentPointsToString,
  remainingPoints,
  progressProps,
  nextLevelId,
  programListProperties,
  currentPoints,
  casinoLoyaltyProgramInfoRouteName,
  modalSelector,
  modalProps,
  onClose,
  headerProperties,
  onCloseAll,
  iconProps,
  modalIconSize,
  titleProps,
  balanceProps,
  descriptionText,
  currentLevelCardProperties,
  currentLevelId,
  levelSettingsWithStatus,
} = useCasinoLoyaltyProgramRoutePage(props);
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramRoutePage'"
    :class="{
      [$style['casino-loyalty-program-page']]: true,
      [$style['casino-loyalty-program-page--no-scroll']]: selectedId,
    }"
  >
    <div :class="$style['casino-loyalty-program-page__header']">
      <div :class="$style['casino-loyalty-program-page__header-title']">
        {{ $t('WEB2_LOYALTY_PROGRAM_MY_LEVEL') }}
      </div>
    </div>
    <CasinoLoyaltyProgramCurrentLevelCard
      v-bind="currentLevelCardProperties"
      :class="$style['casino-loyalty-program-page__current-level']"
      @show-description="showLevelDescription(currentLevelId)"
    />
    <CasinoLoyaltyProgramNextLevelInfo
      :current-points="currentPointsToString"
      :remaining-points="remainingPoints"
      :progress="progressProps(nextLevelId || 0)"
      :level-data-list="programListProperties(nextLevelId || 0)"
      :level-title="currentLevelCardProperties.nextLevelTitle"
    />
    <CasinoLoyaltyProgramSwiper
      :cards="levelSettingsWithStatus"
      :current-points="currentPoints"
      :has-navigation="!!'1'"
      :no-header-title-padding="false"
      :card-size="CasinoLoyaltyProgramCardSizes.SIZE_124"
      no-padding
      @click-card="showLevelDescription"
    />
    <CasinoLoyaltyLobby
      :class="$style['casino-loyalty-program-page__games']"
    />
    <router-link
      :to="{ name: casinoLoyaltyProgramInfoRouteName }"
      :class="$style['casino-loyalty-program-page__how-it-works-link']"
    >
      {{ $t('WEB2_LOYALTY_PROGRAM_CASINO_INFO') }}
    </router-link>
    <Teleport
      v-if="selectedId"
      :to="modalSelector"
    >
      <DefaultModal
        v-bind="modalProps"
        :class="$style['casino-loyalty-program-page__modal-description']"
        @close="onClose"
      >
        <template #header>
          <ModalWindowHeader
            v-bind="headerProperties"
            @prefix-click="onClose"
            @suffix-click="onCloseAll"
          />
        </template>
        <template #content>
          <div :class="$style['casino-loyalty-program-page__description']">
            <CasinoLoyaltyProgramIcon
              v-bind="iconProps"
              :size="modalIconSize"
            />
            <div>
              <CasinoLoyaltyProgramTitle v-bind="titleProps" />
              <BonusLoyaltyProgramBalance
                v-bind="balanceProps"
                has-no-hover
              />
            </div>
            <CasinoLoyaltyProgramList
              v-bind="programListProperties(selectedId)"
            />
            <div :class="$style['casino-loyalty-program-page__description-text']">
              {{ descriptionText(selectedId) }}
            </div>
          </div>
        </template>
      </DefaultModal>
    </Teleport>
  </div>
</template>

<style lang="scss" module>
.casino-loyalty-program-page {
  &--no-scroll {
    overflow-y: inherit;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 18px 18px 8px;

    &-title {
      @include medium\18\20;

      color: var(--TextDefault);
    }

    &-counter {
      @include for-hover {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__current-level {
    margin-bottom: 16px;

    @include is-app-layout-desktop {
      margin-bottom: 8px;
    }
  }

  &__modal-description {
    background-color: var(--LoyaltyLevelLayer);
  }

  &__description {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;

    &-text {
      @include regular\14\24\025;

      color: var(--TextPrimary);
      text-align: left;
    }
  }

  &__games {
    @include is-app-layout-desktop {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__how-it-works-link {
    @include regular\14\24\025;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    margin: 0 auto 32px;
    color: var(--TextPrimary);
    text-decoration: underline;
    cursor: pointer;

    @include for-hover {
      &:hover {
        color: var(--BrandHighlight);
      }
    }
  }

  @include is-app-layout-phone {
    padding-right: 8px;
    padding-left: 8px;
  }

  @include is-app-layout-desktop {
    padding: 16px 24px 0;
  }
}

@include for-os-windows {
  .casino-loyalty-program-page--no-scroll {
    overflow-x: inherit;
  }
}
</style>
