<script lang="ts" setup>
import { useCasinoLoyaltyLobby } from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyLobby/composables';
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';

const {
  lobbyType,
  skeletons,
  gameClick,
} = useCasinoLoyaltyLobby();
</script>

<template>
  <LobbyBlock v-auto-id="'CasinoLoyaltyLobby'"
    class="casino-loyalty-lobby"
    test-el="casino-loyalty-lobby"
    :lobby-type="lobbyType"
    is-modal
    no-background
    is-header-with-horizontal-margin
    :skeletons="skeletons"
    @game-click="gameClick"
  />
</template>
