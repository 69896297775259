import type { I18nFunctionReactive } from '@core/i18n';
import type { FormatMoneyFunc } from '@core/money';

import type { BonusLoyaltyProgramBalanceProps } from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/types';
import {
  CasinoLoyaltyProgramLevelStatus,
} from 'web/src/modules/casino-loyalty-program/store/types';

export default function getBalanceProps(
  status: string | undefined,
  requiredPoints: number | undefined,
  currentPoints: number,
  $t: I18nFunctionReactive,
  formatMoney: FormatMoneyFunc,
): BonusLoyaltyProgramBalanceProps {
  const requiredPointsFormatted = formatMoney(requiredPoints || 0, { currency: 'L' });
  const currentPointsFormatted = formatMoney(currentPoints || 0, {
    hideCurrency: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let text = '';

  if (status === CasinoLoyaltyProgramLevelStatus.PROCESS
    || status === CasinoLoyaltyProgramLevelStatus.LOCK) {
    text = `${currentPointsFormatted} / ${requiredPointsFormatted}`;
  } else {
    text = $t('WEB2_LOYALTY_PROGRAM_LEVEL_FINISHED').value;
  }

  return { text };
}
